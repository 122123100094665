import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import {
  TextField,
  InputAdornment,
  makeStyles,
  Button,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    padding: '0px',
    fontSize: '1rem',
    '&.shrink': {
      backgroundColor: 'white',
      padding: '2px',
      borderRadius: '5px',
    },
  },
  searchIconBackground: {
    height: '56px',
    width: '56px',
    position: 'relative',
    left: '39px',
    background: theme.workwaveBlue,
  },
  searchIcon: {
    color: '#fff',
    height: '56px',
    width: '56px',
    padding: '6px',
    background: theme.workwaveBlue,
    borderRadius: '0 8px 8px 0',
  },
  searchField: {
    background: 'transparent',
    paddingRight: '0',
    padding: '0',
    borderRadius: '8px',
    border: 'none',
    maxWidth: '100%',
    width: '765px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  root: {
    borderRadius: '8px',
    background: '#fff',
  },
  fieldBorder: {
    border: '2px solid #002D5C',
  },
  form: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
  },
}));

export const IndustrySearch = ({
  handleSubmit,
  industries,
  searchRef,
  handleChange,
}) => {
  const [sortedIndustries, setSortedIndustries] = useState([]);

  //sort drop down results so top 14 show up first sorted by importance, then all others alphabetically
  useEffect(() => {
    const other = industries.edges
      .filter((industry) => !industry.node.primary)
      .sort((a, b) => a.node.title.localeCompare(b.node.title));
    const first = industries.edges
      .filter((industry) => industry.node.primary)
      .sort((a, b) => a.node.title.localeCompare(b.node.title));

    setSortedIndustries([...first, ...other]);
  }, []);

  console.log(sortedIndustries.filter((industry) => !industry.node.noindex));
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Autocomplete
        className={classes.searchFieldParent}
        id='industries filter search'
        freeSolo
        style={{ borderRight: 'none', background: 'transparent' }}
        ref={searchRef}
        onInputChange={(e, value) => handleChange(e, value)}
        options={sortedIndustries
          .filter((industry) => !industry.node.noindex)
          .map((options) => {
            return options.node.title;
          })}
        renderInput={(params) => (
          <TextField
            className={classes.searchField}
            {...params}
            label='Search for your industry'
            margin='normal'
            variant='outlined'
            color='primary'
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                shrink: 'shrink',
              },
            }}
            OutlinedInputProps={{
              classes: { root: classes.fieldBorder },
            }}
            InputProps={{
              classes: {
                root: classes.inputLabel,
                root: classes.root,
              },
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <InputAdornment
                  className={classes.searchIconBackground}
                  position='end'
                >
                  {/* <FontAwesomeIcon
										icon={['fad', 'search']}
										style={{
											color: '#002D5C',
											height: '60px',
											width: '60px',
										}}
									/> */}
                  <SearchTwoToneIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </form>
  );
};
